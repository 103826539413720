<template>
  <main class="member-level-setting">
    <PageTitle title="會員等級設定" hideBtn />
    <MemberLevelTable
      v-loading="loading.table"
      :tableData="tableData"
      :memberCount="memberLevelCount"
      @refresh="refresh"
    />
  </main>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberLevelTable from './components/MemberLevelTable.vue'
import { GetMemberLevelConfig, FindMemberLevelCount } from '@/api/memberLevel'
import { useTable } from '@/use/table'

export default defineComponent({
  name: 'MemberLevelSetting',
  components: { PageTitle, MemberLevelTable },
  setup () {
    const showEditHistory = ref(false)
    const memberLevelCount = ref([])
    const {
      shopId,
      loading,
      pageStartIndex,
      tableData,
      tableDataCount,
      tableOptions,
      fetchData,
    } = useTable()

    const getMemberLevelCount = async () => {
      const [res, err] = await FindMemberLevelCount({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      memberLevelCount.value = res
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([fetchData(GetMemberLevelConfig, payload)])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      await getMemberLevelCount()
      loading.table = false
    }

    onMounted(async () => {
      refresh()
    })

    return {
      loading,
      tableData,
      tableDataCount,
      tableOptions,
      refresh,
      showEditHistory,
      memberLevelCount,
    }
  },
})
</script>

<style scoped lang="postcss"></style>
