var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"member-level-edit-history-detail-block"},[_c('div',{staticClass:"base-info-list"},_vm._l((_vm.displayBaseInfo),function(field){return _c('div',{key:field.value,staticClass:"flex",staticStyle:{"gap":"12px"}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(field.label))]),_c('p',{staticClass:"value"},[_vm._v(_vm._s(field.value))])])}),0),(_vm.displayEditedData.length)?_c('el-table',{staticStyle:{"margin-top":"8px"},attrs:{"data":_vm.displayEditedData}},[_c('el-table-column',{attrs:{"label":_vm.isInitRecord ? '新建項目' : '調整項目',"prop":"field"}}),_c('el-table-column',{attrs:{"label":_vm.isInitRecord ? '新建內容' : '調整前',"prop":"before"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"text-sub"},[(
              !['conditionLevelUp', 'conditionLevelRemain'].includes(
                scope.row.key
              )
            )?_c('p',[_vm._v(" "+_vm._s(_vm.get(scope, 'row.before'))+" ")]):_vm._e(),(
              scope.row.key === 'conditionLevelUp' ||
                scope.row.key === 'conditionLevelRemain'
            )?_c('div',[(
                _vm.get(scope, 'row.before.period') ||
                  _vm.get(scope, 'row.before.amount')
              )?_c('div',[(_vm.get(scope, 'row.before.period'))?_c('span',[_vm._v("指定期間 "+_vm._s(_vm.get(scope, 'row.before.period'))+" 月")]):_vm._e(),(_vm.get(scope, 'row.before.amount'))?_c('span',[_vm._v("消費滿 "+_vm._s(_vm.get(scope, 'row.before.amount'))+" ")]):_vm._e(),(scope.row.key === 'conditionLevelUp')?_c('span',[_vm._v(_vm._s(_vm.get(scope, 'row.before.levelUpCondition') === 'onceAmount' ? '次' : '元'))]):_vm._e(),(scope.row.key === 'conditionLevelRemain')?_c('span',[_vm._v(_vm._s(_vm.get(scope, 'row.before.levelUpCondition') === 'onceAmount' ? '次' : '元'))]):_vm._e()]):_c('span',[_vm._v("-")])]):_vm._e()])]}}],null,false,1407100811)}),(!_vm.isInitRecord)?_c('el-table-column',{attrs:{"label":"調整後","prop":"after"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"text-primary-100 text-sub"},[(_vm.get(scope, 'row.defaultShow'))?_c('p',{staticClass:"text-primary-100"},[_vm._v(" "+_vm._s(scope.row.after)+" ")]):_vm._e(),(
              scope.row.key === 'levelUpSources' ||
                scope.row.key === 'levelRemainSources'
            )?_c('ul',[(_vm.get(scope, 'row.after.add'))?_c('li',{staticClass:"list-disc"},[_vm._v(" 啟用："+_vm._s(_vm.get(scope, 'row.after.add'))+" ")]):_vm._e(),_c('br'),(_vm.get(scope, 'row.after.disable'))?_c('li',{staticClass:"list-disc"},[_vm._v(" 關閉："+_vm._s(_vm.get(scope, 'row.after.disable'))+" ")]):_vm._e(),(_vm.get(scope, 'row.after.default'))?_c('p',[_vm._v(" "+_vm._s(_vm.get(scope, 'row.after.default'))+" ")]):_vm._e()]):_vm._e(),(
              scope.row.key === 'conditionLevelUp' ||
                scope.row.key === 'conditionLevelRemain'
            )?_c('div',[(
                _vm.get(scope, 'row.after.period') ||
                  _vm.get(scope, 'row.after.amount')
              )?_c('div',[(_vm.get(scope, 'row.after.period'))?_c('span',[_vm._v("指定期間 "+_vm._s(_vm.get(scope, 'row.after.period'))+" 月")]):_vm._e(),(_vm.get(scope, 'row.after.amount'))?_c('span',[_vm._v("消費滿 "+_vm._s(_vm.get(scope, 'row.after.amount'))+" ")]):_vm._e(),(scope.row.key === 'conditionLevelUp')?_c('span',[_vm._v(_vm._s(_vm.get(scope, 'row.after.levelUpCondition') === 'onceAmount' ? '次' : '元'))]):_vm._e(),(scope.row.key === 'conditionLevelRemain')?_c('span',[_vm._v(_vm._s(_vm.get(scope, 'row.after.levelUpCondition') === 'onceAmount' ? '次' : '元'))]):_vm._e()]):_c('span',[_vm._v("-")])]):_vm._e()])]}}],null,false,1745608365)}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }