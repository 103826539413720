import { render, staticRenderFns } from "./MemberLevelPushMessageNoticeModal.vue?vue&type=template&id=9021ae8e&scoped=true"
import script from "./MemberLevelPushMessageNoticeModal.vue?vue&type=script&lang=js"
export * from "./MemberLevelPushMessageNoticeModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9021ae8e",
  null
  
)

export default component.exports