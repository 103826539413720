import { memberLevelFieldsConfig, levelUpChannelsConfig, conditionOptionsConfig } from '@/config/memberLevel'
import { get, map, difference } from 'lodash'

export const useMemberLevelHistory = () => {
  const handleTimeRangeData = (data) => {
    const oldTime = get(data, 'old.time')
    const oldUnit = get(data, 'old.unit')
    const newTime = get(data, 'new.time')
    const newUnit = get(data, 'new.unit')

    const fieldData = {
      field: get(memberLevelFieldsConfig, `${data.field}.label`),
      before: oldUnit === 'forever' ? '永久' : `${oldTime} 個月`,
      after: newUnit === 'forever' ? '永久' : `${newTime} 個月`,
      defaultShow: true,
    }
    return fieldData
  }

  const handleSourceData = (data) => {
    const oldSource = map(get(data, 'old', []), source => get(levelUpChannelsConfig, `${source}.label`))
    const newSource = map(get(data, 'new', []), source => get(levelUpChannelsConfig, `${source}.label`))
    const disable = difference(oldSource, newSource).join('、')
    const add = difference(newSource, oldSource).join('、')

    const fieldData = {
      key: data.field,
      field: get(memberLevelFieldsConfig, `${data.field}.label`),
      before: oldSource.join('、') || '-',
      after: newSource.length ? { disable, add: newSource.join('、') } : { default: '-' },
      defaultShow: false,
    }
    return fieldData
  }

  const handleConditionTypeData = (data) => {
    const oldData = get(conditionOptionsConfig, `${get(data, 'old')}.label`)
    const newData = get(conditionOptionsConfig, `${get(data, 'new')}.label`)

    const fieldData = {
      key: data.field,
      field: get(memberLevelFieldsConfig, `${data.field}.label`),
      before: oldData || '-',
      after: newData || '-',
      defaultShow: true,
    }
    return fieldData
  }

  const handleConditionData = (data) => {
    const oldAmount = get(data, 'old.amount')
    const newAmount = get(data, 'new.amount')
    const oldPeriod = get(data, 'old.period')
    const newPeriod = get(data, 'new.period')

    const fieldData = {
      key: data.field,
      field: get(memberLevelFieldsConfig, `${data.field}.label`),
      before: { period: oldPeriod, amount: oldAmount },
      after: { period: newPeriod, amount: newAmount },
      defaultShow: false,
    }

    return fieldData
  }

  return {
    handleTimeRangeData,
    handleSourceData,
    handleConditionTypeData,
    handleConditionData,
  }
}
