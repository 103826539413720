<template>
  <el-drawer
    title="編輯紀錄"
    :visible="true"
    size="600px"
    class="reservation-order-detail"
    @close="$emit('close')"
  >
    <div class="content-wrapper">
      <el-steps
        v-if="historyData.length >= 1"
        direction="vertical"
        :active="historyData.length"
      >
        <el-step
          v-for="(log, index) in historyData"
          :key="log.id"
          :description="log.time"
        >
          <template slot="description">
            <div style="padding-bottom: 70px">
              <p class="dateTime">{{ formatDate(log.updatedAt) }}</p>
              <MemberLevelEditHistoryDetailBlock
                :isInitRecord="index === historyData.length - 1"
                :historyData="log"
                :level="level"
              />
            </div>
          </template>
        </el-step>
      </el-steps>

      <EmptyBlock v-else />
    </div>
  </el-drawer>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { GetMemberLevelHistory } from '@/api/memberLevel'
import MemberLevelEditHistoryDetailBlock from './MemberLevelEditHistoryDetailBlock.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { formatDate } from '@/utils/date'
import { filter } from 'lodash'

export default defineComponent({
  name: 'MemberLevelEditHistoryDetail',
  components: { MemberLevelEditHistoryDetailBlock, EmptyBlock },
  props: {
    level: [Number, String],
  },
  setup (props) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const historyData = ref([])

    onMounted(() => {
      simpleFetch(
        GetMemberLevelHistory,
        { shopId: shopId.value, level: props.level },
        (res) => {
          historyData.value = filter(res, (item) => item)
        },
      )
    })

    return { historyData, formatDate }
  },
})
</script>

<style lang="postcss" scoped>
.content-wrapper {
  @apply px-[20px];
}
.dateTime {
  @apply text-gray-60 text-sub pb-[8px];
}

::v-deep .el-step__description {
  @apply pr-0 top-[-4px] relative;
}

::v-deep .el-step__icon.is-text {
  @apply top-[-4px] h-[15px] w-[15px] ml-[5px] bg-primary-100;
}

::v-deep .el-step__icon-inner {
  @apply hidden;
}
</style>
